import React from 'react';
import '../../App.css';
import Dashboard from '../Dashboard';

function Designer() {
    return (
        <>
          <Dashboard/>
        </>
      );
}
export default Designer;